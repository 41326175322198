/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import Formulario_Producto from "../Formularios/Formulario_Producto";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {subirVariosVideos} from "../../Servicios/Storage/subirVariosVideos";

const Dialogo_Producto = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        console.log('entro')
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            if (entidad.stock === 'true') {
                obj.stock = true
            } else {
                obj.stock = false
            }
            console.log(entidad)
            abrirCargador('Guardando Cambios')
            subirImagenFile(entidad.img, 'productos').then((dox) => {

                if (dox.res) {
                    obj.img = dox.data
                    obj.fecha = new Date().getTime()


                    subirImagenFile(entidad.imgAtras, 'productos').then((dep) => {

                        if (dep.res) {
                            obj.imgAtras = dep.data

                            subirVariasImagenFile(entidad.imgs, 'productos').then((vis) => {
                                if (vis.res) {
                                    obj.imgs = vis.data


                                    subirVariosVideos(entidad.videos, 'videos').then((mes) => {
                                        if (mes.res) {
                                            obj.videos = mes.data
                                            guardarProducto(obj)
                                        }
                                    })


                                }
                            })


                        } else {
                            cerrarCargador()
                            alert('Error al subir segunda imagen, por favor intentelo nuevamente')
                        }

                    })


                } else {
                    cerrarCargador()
                    alert('Error al subir imagen, por favor intentelo nuevamente')
                }

            })


        })

    }

    const guardarProducto = (obj) => {
        guardarDoc('productos', obj).then((des) => {
            if (des.res) {
                cerrar()
                cerrarCargador()
                alert('Cambios guardados con exito')
                realizado()
            } else {
                cerrarCargador()
                alert('Error al subir datos, por favor intentelo nuevamente')
            }
        })

    }


    const borrar = () => {
        abrirCargador('Borrando Coleccion')
        borrarDoc('productos', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Producto props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Producto</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Producto