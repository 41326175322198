/**************************************************
 * Nombre:       Login
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid, Typography, useMediaQuery} from "@mui/material";
import {useFormulario} from "../Modulo_formularios/Hooks/useFormulario";
import {useLoaders} from "../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../Tema";
import './login.css'
import {getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {Google} from "@mui/icons-material";
import {guardarDoc} from "../Servicios/BD/guardarDoc";
import {useAvisos} from "../Modulo_Avisos/Hooks/useAvisos";

const Login = ({logo, logoRedondo}) => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logoRedondo})
    const {Avisos, nuevoAviso, terminar} = useAvisos({funcionUno: cerrarCargador, funcionDos: limpiarEntidad})

    const ingresar = () => {

        const auth = getAuth();
        auth.languageCode = 'es';

        const provider = new GoogleAuthProvider();

        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });

    }

    const enviarSolicitud = async () => {

        let entidad = await obtenerEntidad()
        entidad.fecha = new Date().getTime()
        entidad.correo = entidad.correo.replaceAll(' ', '')
        abrirCargador('Enviando datos...')
        entidad.estado = 'Solicitante'
        let dox = await guardarDoc('usuarios', entidad)
        if (dox.res) {
            terminar({aviso: 'Datos enviados con exito'})
        } else {
            terminar({aviso: 'Algo salio mal, por favor intentalo nuevamente', variante: 'error'})
        }

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{backgroundColor: '#fff'}}
        >

            <Cargador/>

            <Avisos/>

            <Typography sx={{position: 'fixed', right: 12, bottom: 8}}>v 0.1.2</Typography>

            <Grid item container lg={7} sm={5} xs={12} className={masSM ? 'clase' : 'claseCell'}
                  sx={{justifyContent: 'center', py: 6, backgroundColor: '#000', minHeight: sCell ? '30vh' : '100vh'}}>
                <img src={logo} width={'30%'} height={'auto'}/>
            </Grid>

            <Grid item container lg={5} sm={7} xs={12} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{backgroundColor: '#fff', minHeight: sCell ? '45vh' : '100vh', px: sCell ? 4 : 0}}
                >


                    <Grid item container lg={8} sm={9} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            sx={{py: 8}}
                        >

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <Typography sx={{fontSize: 32, fontWeight: 800, px: 1}}>Hola!</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: -2}}>
                                <Typography sx={{fontSize: 16, fontWeight: 300, px: 1}}>Accede al panel de
                                    control</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                <Button fullWidth startIcon={<Google/>} onClick={() => ingresar()}
                                        sx={{pt: 1.5, pb: 1.5, mx: 1}}
                                >Ingresar mediante
                                    Google</Button>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12} sx={{marginTop: 6}}>
                                <Divider sx={{width: '100%'}}/>
                            </Grid>

                            {/*    <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: 2}}>
                                <Typography sx={{fontSize: 16, fontWeight: 400, px: 1}}>Solicita Acceso</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: 'flex-start', marginTop: -2}}>
                                <Formulario_Login props={props}/>
                            </Grid>


                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end', px: 1}}>
                                <Button
                                    onClick={() => enviarSolicitud()}
                                    variant={'outlined'} sx={{px: 4}}>Solicitar</Button>
                            </Grid> */}


                        </Grid>
                    </Grid>


                </Grid>
            </Grid>


        </Grid>
    )

}
export default Login    